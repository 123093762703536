/**
 * Format: FEATURE_ACTION
 */
export enum AnalyticsEvents {
    // When the user reaches projects list
    HOME_LIST_REACHED = 'home_list_reached',
    // When the user reaches project comparative
    HOME_ANALYSIS_REACHED = 'home_analysis_reached',
    // When the user changes some settings
    HOME_SETTINGS_CHANGED = 'settings_changed',

    // When the user pins/unpins a project
    HOME_PROJECT_PINNED = 'project_pinned',
    HOME_PROJECT_UNPINNED = 'project_unpinned',

    // When the user operates with tags
    HOME_TAG_CREATED = 'tag_created',
    HOME_TAG_ATTACHED = 'tag_attached',
    HOME_TAG_DETACHED = 'tag_detached',

    // When the user searches for a project in analysis
    HOME_ANALYSIS_SEARCH = 'home_analysis_search',

    // When the user toggles promotions in analysis summary
    HOME_ANALYSIS_SUMMARY_FILTER_ADD_PROMOTION = 'home_analysis_summary_filter_add_promotion',
    HOME_ANALYSIS_SUMMARY_FILTER_REMOVE_PROMOTION = 'home_analysis_summary_filter_remove_promotion',

    // When the user reaches KPIS
    KPIS_REACHED = 'kpis_reached',

    // When the user clicks on a KPI
    KPIS_VALORIZATION_CLICKED = 'kpis_valorization_clicked',
    KPIS_MIX_CLICKED = 'kpis_mix_clicked',
    KPIS_CERTIFIEDTRACEABILITIES_CLICKED = 'kpis_certified_traceabilities_clicked',
    KPIS_MANDATORYFLOWS_CLICKED = 'kpis_mandatory_flows_clicked',

    // When link to EGR/PGR configuration clicked
    KPIS_MANDATORYFLOWS_LINK_CLICKED = 'kpis_mandatory_flows_link_clicked',

    // When the user reaches managed tons to egr detail chart
    KPIS_MANDATORYFLOWS_DETAIL_EGR_REACHED = 'kpis_mandatory_flows_detail_egr_reached',

    // When the user reaches managed tons to pgr detail chart
    KPIS_MANDATORYFLOWS_DETAIL_PGR_REACHED = 'kpis_mandatory_flows_detail_pgr_reached',

    // When the user clicks on a bar in the managed tons to egr/pgr detail chart
    KPIS_MANDATORYFLOWS_DETAIL_BAR_CLICKED = 'kpis_mandatory_flows_detail_bar_clicked',

    // When user closes alert message about costs (now costs aren't visible to all users)
    ALERT_COSTS_DISMISSED = 'alert_costs_dismissed',

    // When user clicks traceability dropdown at traceability summary chart to change unit (Y axis)
    DROPDOWN_UNIT_TRACEABILITY_SUMMARY_CLICKED = 'dropdown_unit_traceability_summary_clicked',

    // Manual traceability
    TRACEABILITY_CREATED_MANUALLY = 'traceability_created_manually',

    // Reuse create
    REUSE_CREATED = 'reuse_created',

    // Automatic reading upload
    AUTOMATIC_READING_PRESELECT_FLOW = 'automatic_reading_preselect_flow',

    // Automatic reading approve/reject
    AUTOMATIC_READING_INLINE_APPROVED = 'automatic_reading_inline_approveed',
    AUTOMATIC_READING_DETAIL_APPROVED = 'automatic_reading_detail_approveed',
    AUTOMATIC_READING_MASSIVE_APPROVED = 'automatic_reading_massive_approveed',
    AUTOMATIC_READING_INLINE_REJECTED = 'automatic_reading_inline_rejected',
    AUTOMATIC_READING_DETAIL_REJECTED = 'automatic_reading_detail_rejected',
    AUTOMATIC_READING_MASSIVE_REJECTED = 'automatic_reading_massive_rejected',
    AUTOMATIC_READING_MASSIVE_RETRIED = 'automatic_reading_massive_retried',
    AUTOMATIC_READING_PREVIEW_ROTATED = 'automatic_reading_preview_rotated',

    // Automatic reading filters
    AUTOMATIC_READING_FILTEREDBY_STATUS = 'automatic_reading_filteredby_status',
    AUTOMATIC_READING_FILTEREDBY_EMAIL = 'automatic_reading_filteredby_email',
    AUTOMATIC_READING_FILTEREDBY_FILENAME = 'automatic_reading_filteredby_filename',
    AUTOMATIC_READING_FILTEREDBY_DATE = 'automatic_reading_filteredby_date',
    AUTOMATIC_READING_FILTEREDBY_SORT = 'automatic_reading_filteredby_sort',
    AUTOMATIC_READING_FILTEREDBY_ORIGIN = 'automatic_reading_filteredby_origin',

    // Promotion detail
    PROMO_WASTE_MANAGER_TAB_CLICKED = 'promo_waste_manager_tab_clicked',
    PROMO_USERS_TAB_CLICKED = 'promo_users_tab_clicked',
    PROMO_REPORT_TAB_CLICKED = 'promo_report_tab_clicked',
    PROMO_CONFIGURATION_TAB_CLICKED = 'promo_configuration_tab_clicked',

    PROMO_VIEW_MORE_CLICKED = 'promo_view_more_clicked',
    PROMO_VALORIZA_BADGE_CLICKED = 'promo_valoriza_badge_clicked',
    PROMO_ALERT_CLICKED = 'promo_alert_clicked',
    PROMO_VALORIZATION_TABLE_SWITCH_CLICKED = 'promo_valorization_table_switch_clicked',
    PROMO_VALORIZATION_INDIVIDUAL_BAR_CLICKED = 'promo_valorization_individual_bar_clicked',

    PROMOSETTINGS_SAVE_CLICKED = 'promo_settings_save_clicked',

    // Check via Hotjar if user is having a better/worst experience with 1 selector instead of 3 (ler, waste manager and carrier)
    WASTE_REGISTER_WASTE_FLOW_CLICKED = 'waste_register_waste_flow_clicked',
    WASTE_REGISTER_DI_LINK_ERROR = 'waste_register_di_link_error',

    ONBOARDING_VIDEO_CLICKED = 'onboarding_video_clicked',
    SUPPORT_EMAIL_CLICKED = 'support_email_clicked',
    SUPPORT_CALENDAR_CLICKED = 'support_calendar_clicked',

    SUPPORT_OPENED = 'support_opened',
    SUPPORT_REQUEST_CLICKED = 'support_request_clicked',
    SUPPORT_REQUEST_SENT = 'support_request_sent',
    SUPPORT_VIDEO_CLICKED = 'support_video_clicked',
    SUPPORT_FAQ_CLICKED = 'support_faq_clicked'
}
