import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import EllipsisText from 'components/BasicComponents/Text/EllipsisText';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import TagBadge from 'features/tags/components/TagBadge';
import { Status } from 'modules/comparative';
import { KPIStatus, PromotionKPIs } from 'modules/promotion-kpis';
import { getGoalsSummaryText } from '../../helpers/comparative.helpers';

export const TagsCell = (params) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '0.4rem' }}>
            {params.value.map((tag: any, index) => (
                <TagBadge name={tag.name} color={tag.color} key={index} />
            ))}
        </div>
    );
};

export const PromotionCell = (params) => {
    return (
        <Link
            className="link"
            to={`/promotion/${params.data.promotion.id}`}
            style={{ alignItems: 'center', display: 'flex', height: '100%' }}
        >
            {params.value}
        </Link>
    );
};

export const BooleanCell = (params: { value?: boolean | null; severity?: number; tooltip?: string }) => {
    const [t] = useTranslation('comparative');
    const status: KPIStatus =
        (params.value === null && KPIStatus.INDETERMINATE) ||
        (params.value === true && KPIStatus.OK) ||
        KPIStatus[(params.severity === 2 && 'WARNING') || 'FAILED'];

    const tooltip = t(`tooltips.${params.tooltip}.${status}`, { defaultValue: null });

    return (
        <Tooltip
            msg={
                params.tooltip ? (
                    <TooltipContent>
                        <TooltipContentLabel className="Comparative__kpi-msg">{tooltip}</TooltipContentLabel>
                    </TooltipContent>
                ) : null
            }
        >
            <p className={`Comparative__kpi Comparative--${status}`}>
                {params.value ? t('yes') : t('no')}
                <KPIStatusIcon status={status} />
            </p>
        </Tooltip>
    );
};

export const StatusMetCell = (props: {
    value: any;
    text: string;
    status: Status | null;
    promotionId: string;
    kpiKey: keyof typeof PromotionKPIs;
}) => {
    const [t] = useTranslation();
    const status = KPIStatus[props.status || 'INDETERMINATE'];
    const kpi = PromotionKPIs[props.kpiKey];
    const alarm = props.value !== null && props.value !== undefined ? status : 'nodata';

    return (
        <Tooltip
            msg={
                <TooltipContent>
                    <TooltipContentValue>{t(`comparative:goals.${props.kpiKey}`)}</TooltipContentValue>
                    <TooltipContentLabel className="Comparative__kpi-msg">
                        <KPIStatusIcon status={status} />
                        {t(`kpis:${kpi}.alarm.${alarm}.title`)}
                    </TooltipContentLabel>
                    <TooltipContentDate>{t(`comparative:tooltips.kpis.cta`)}</TooltipContentDate>
                </TooltipContent>
            }
        >
            <Link
                to={`/promotion/${props.promotionId}/expedient/summary?kpi=${kpi}`}
                className={`Comparative__kpi Comparative--${status} Comparative--clickable`}
            >
                <span>{props.text}</span>
                <KPIStatusIcon status={status} />
            </Link>
        </Tooltip>
    );
};

export const ArrayCell = (params) => {
    return params.value.map((m) => m.name).join(', ');
};

export const ObservationsCell = (props: { isCoCircular: boolean; text?: string; action: () => void }) => {
    return (
        <p className="Comparative__viewMoreObservations">
            <EllipsisText>{props.text || ''}</EllipsisText>

            {props.text && <SmallButton titulo={'Ver más'} action={() => props.action()} />}
            {!props.text && props.isCoCircular && <SmallButton titulo={'Añadir'} action={() => props.action()} />}
        </p>
    );
};

export const GoalsSummaryCell = (value: Status | null) => {
    const status = KPIStatus[value || 'INDETERMINATE'];
    const text = getGoalsSummaryText(value);

    return (
        <p className={`Comparative__kpi --Summary Comparative--${status}`}>
            <span>{text}</span>
            <KPIStatusIcon status={status} />
        </p>
    );
};
