import { IconAlertCircle } from '@tabler/icons';
import { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { blobToBase64, compressFile } from '../../../utils/helpers/files.helpers';
import File from '../File/File';
import './DragNDrop.scss';
import AlertCard from '../Cards/AlertCard';

const DragNDrop = ({
    label,
    files,
    setFiles,
    /** @type {'base64' | 'file'} */
    format = 'base64',
    showFilelist = true,
    maxFiles = 0, // 0 = no limit
    maxSizeMb = undefined, // No limit
    removeFunction,
    forceUniqueName = true,
    fileProp = 'fichero',
    contentTypeProp = 'ficheroContentType',
    fileNameProp = 'nombreFichero',
    fileTitleProp = 'nombreFichero',
    urlProp = 'preSignedUrl',
    className,
    captureFromCameraIfMobile = true,
    children,
    disabled = false,
    accept
}) => {
    const [t] = useTranslation();

    label = label || t('attachFiles', { ns: 'common' });

    const onDrop = useCallback(
        async (acceptedFiles) => {
            const maxFilesReached = maxFiles !== 0 && files.length > maxFiles;
            if (maxFilesReached) return;

            const newFiles =
                (format === 'base64' && (await filesToBase64(acceptedFiles))) ||
                (format === 'file' && filesToArray(acceptedFiles)) ||
                [];

            setFiles((prevFiles) => [...newFiles, ...prevFiles]);
        },
        [files]
    );

    const filesToArray = (files) => {
        return Array.from(files);
    };

    const filesToBase64 = async (acceptedFiles) => {
        const processedFiles = [];
        for (let i = 0; i < acceptedFiles?.length; i++) {
            const file = acceptedFiles[i];

            let { processedFile, contentType, fileName } = await compressFile(file, file?.size);
            processedFile = await blobToBase64(processedFile);
            fileName = fileName || file?.name;

            if (processedFile) {
                // unshift its like push but at the beginning of the array
                processedFiles.unshift({
                    [fileProp]: processedFile,
                    [contentTypeProp]: contentType || file?.type,
                    [fileNameProp]: forceUniqueName ? getUniqueName(fileName, processedFiles) : fileName
                });
            }
        }
        return processedFiles;
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections } = useDropzone({
        onDrop,
        maxFiles,
        maxSize: maxSizeMb * 1024 * 1024,
        accept,
        disabled
    });

    const removeFileFromArray = async (index) => {
        let filesAux = JSON.parse(JSON.stringify(files));
        filesAux.splice(index, 1);
        setFiles(filesAux);
    };

    const getUniqueName = (fileName, prevFiles) => {
        let uniqueName = fileName;

        if (prevFiles?.some((file) => file[fileNameProp] === fileName)) {
            let newNameFound = false;
            let i = 1;
            while (!newNameFound) {
                const nameWithNumber = fileName + ' (' + i + ')';
                if (!prevFiles?.some((file) => file[fileNameProp] === nameWithNumber)) {
                    uniqueName = nameWithNumber;
                    newNameFound = true;
                } else {
                    i++;
                }
            }
        }

        return uniqueName;
    };

    const getFileName = (file) => {
        return file?.[fileTitleProp] || file?.[fileNameProp] || file?.name;
    };

    const rejectedErrors = Object.entries(
        fileRejections
            .flatMap(({ errors }) => errors)
            .reduce((acc, { code }) => ({ ...acc, [code]: (acc[code] || 0) + 1 }), {})
    );

    const compressedFileErrorExplanation = fileRejections?.some(
        ({ file }) => file.type.includes('zip') || file.type.includes('rar')
    );

    const acceptedExtensions = accept?.map((mimeType) => mimeType.split('/')[1]).join(', ');

    return (
        <div className={className ? className : ''}>
            <label className="input__label">{label}</label>
            <div
                {...getRootProps()}
                className={
                    'dragndrop__container ' +
                    (isDragActive ? 'activeStyle ' : '') +
                    (isDragAccept ? 'acceptStyle ' : '') +
                    (isDragReject ? 'rejectStyle ' : '')
                }
            >
                {rejectedErrors.length > 0 && (
                    <AlertCard
                        title={t(`dragndrop.error.title`)}
                        className="dragndrop__rejectedAlert card__alert-error card__alert--borderless"
                        icon={<IconAlertCircle />}
                    >
                        <ul>
                            {rejectedErrors.map(([code, count]) => (
                                <li>
                                    <div>
                                        <Trans
                                            i18nKey={`dragndrop.error.${code}`}
                                            components={{ b: <strong /> }}
                                            values={{
                                                count,
                                                maxFiles,
                                                maxSizeMb,
                                                acceptedExtensions
                                            }}
                                        />
                                    </div>
                                    {compressedFileErrorExplanation && (
                                        <em style={{ marginTop: '0.4rem', display: 'block' }}>
                                            {t(`dragndrop.error.explanation.${code}`)}
                                        </em>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </AlertCard>
                )}
                <input
                    data-testid="drag-n-drop-input"
                    {...getInputProps()}
                    {...(captureFromCameraIfMobile && { capture: 'environment' })}
                />

                {children ? (
                    children
                ) : (
                    <p className="dragndrop__placeholder">
                        {disabled ? t('fillFirstDragNDrop', { ns: 'common' }) : t('dragOrClick', { ns: 'common' })}
                    </p>
                )}

                {showFilelist && (
                    <div className="dragndrop__filesContainer">
                        {files?.map(
                            (file, i) =>
                                // FOR SITE CHECKING
                                file?.[fileProp] !== 'deleted image' && (
                                    <File
                                        key={i}
                                        file={file?.[fileProp]}
                                        url={file?.[urlProp]}
                                        contentType={file?.[contentTypeProp]}
                                        preSignedUrl={file?.[urlProp]}
                                        fileName={getFileName(file)}
                                        deleteAction={
                                            removeFunction === undefined ? removeFileFromArray : removeFunction
                                        }
                                        fileIndex={i}
                                        showDelete={true}
                                        className="dragndrop__file"
                                    />
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DragNDrop;
