import fetcher from 'lib/fetcher';
import { downloadFile } from 'modules/shared';

import { TraceabilitiesCriteria } from '../../domain/TraceabilitiesCriteria';
import { TraceabilitiesApi } from '../../domain/TraceabilitiesApi';
import { Ler, StakeholderInfo } from '../../domain/Traceability/Traceability';
import { carrierAdapter } from './adapters/carrierAdapter';
import { traceabilityAdapter } from './adapters/traceabilityAdapter';
import { wasteManagerAdapter } from './adapters/wasteManagerAdapter';
import { traceabilitiesCriteriaAdapter } from './adapters/traceabilitiesCriteriaAdapter';
import { sumaryTotalsAdapter } from './adapters/sumaryTotalsAdapter';

export const httpTraceabilitiesApi: TraceabilitiesApi = {
    async getTraceabilityDetail({ id, promotionId }) {
        const containerTraceabilityRequest = fetcher(`api/tracing/traceabilities?id.equals=${id}`).then(
            ({ data }) => data.content.traceabilities[0]
        );

        const documentsRequest = fetcher(
            `api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`
        ).then(({ data }) => ({
            di: data?.content?.documents?.find((doc) => doc.type.name === 'DI')
        }));

        const finalTraceabilityRequest = fetcher(`/api/pro-ges-cod-lers/final-traceability/${id}`).then(
            ({ data }) => data
        );

        const [data, { di }, finalTraceability] = await Promise.all([
            containerTraceabilityRequest,
            documentsRequest,
            finalTraceabilityRequest
        ]);

        if (!data) throw new Error('Traceability not found');

        const traceability = traceabilityAdapter({ data, di, finalTraceability });

        return traceability;
    },
    async getDiByTraceabilityId({ id, promotionId }) {
        const request = await fetcher(
            `api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`
        ).then(({ data }) => ({
            di: data?.content?.documents?.find((doc) => doc.type.name === 'DI')
        }));

        return { url: request.di?.url || '' };
    },
    async getTraceabilities(criteria) {
        const params = traceabilitiesCriteriaAdapter(criteria);
        const { data, headers } = await fetcher.get('api/tracing/traceabilities', { params });

        const traceabilities = data.content.traceabilities;
        const count = parseInt(headers['x-total-count']);

        const mappedTraceabilities = traceabilities.map((data) => traceabilityAdapter({ data, di: null }));

        return { traceabilities: mappedTraceabilities, total: count };
    },
    async getLers(params) {
        const { data } = await fetcher(`api/lers/project/${params.promotionId}`, {
            params: {
                'codLer.contains': params.search || null,
                'typeLer.contains': params.search || null
            }
        });

        const lers: Partial<Ler>[] = data?.content?.map((entry) => ({ ...entry, kind: entry.lerKind })) || [];
        return lers;
    },
    async getLerTypes(params) {
        const { data } = await fetcher(`api/lers/project/${params.promotionId}/types`, {
            params: {
                'name.contains': params.search || null
            }
        });
        const lerTypes: Array<{ id: number; name: string }> = data?.content || [];
        return lerTypes;
    },
    async getWasteManagers(params) {
        const { data } = await fetcher('api/maintenance/promo-pro-ges-cod-lers', {
            params: {
                'promocionId.equals': params.promotionId,
                'gestoraName.contains': params.search || null,
                page: params.page || null
            }
        });

        const wasteManagers: StakeholderInfo[] = data.map(wasteManagerAdapter);

        return wasteManagers;
    },
    async getCarriers(params) {
        const { data } = await fetcher('api/maintenance/promo-pro-ges-cod-lers', {
            params: {
                'promocionId.equals': params.promotionId,
                'carrierName.contains': params.search || null,
                page: params.page || null
            }
        });

        const carriers: StakeholderInfo[] = data.map(carrierAdapter);

        return carriers;
    },
    async getCounters({ promotionId }) {
        const getCount = async (criteria: TraceabilitiesCriteria) => {
            const { data } = await fetcher.get<number>('/api/tracing/container-traceability/count', {
                params: traceabilitiesCriteriaAdapter({ ...criteria, promotionId })
            });
            return data as number;
        };

        return {
            completedAndWarning: (await getCount({ status: ['COMPLETED', 'WARNING'] })) || 0
        };
    },
    async removeTraceability({ traceability, operation }) {
        const config: any = {
            message: {
                success: { text: `operations.${operation}.sucess`, ns: 'traceabilities' },
                error: { text: `operations.${operation}.error`, ns: 'traceabilities' }
            }
        };

        await fetcher.delete(`api/tracing/container-traceability/${traceability.id}`, config);
        return;
    },
    async getTraceabilitiesStats({ promotionId, ...criteria }) {
        const params = { ...traceabilitiesCriteriaAdapter(criteria), 'traceabilityStatus.in': 'COMPLETED,WARNING' };
        const kpisRequest = await fetcher(`api/traceability-metrics/promotion/${promotionId}/kpi`, { params });
        const stats = kpisRequest.data.content;

        return { stats };
    },
    async getTraceabilitiesSummaryByDate({ measure, unit, filters: { promotionId, ...filters } }) {
        const params = traceabilitiesCriteriaAdapter({ ...filters, status: ['COMPLETED', 'WARNING'] });
        const url = `api/traceability-metrics/promotion/${promotionId}/${measure}-by-month/unit/${unit}`;

        const summaryRequest = await fetcher(url, { params });
        const summary = summaryRequest.data.content;

        return summary;
    },
    async getTraceabilitiesSummaryTotals({ measure, filters: { promotionId, ...filters } }) {
        const params = traceabilitiesCriteriaAdapter({ ...filters, status: ['COMPLETED', 'WARNING'] });
        // the unit params has no effect in this, because table displays all values (but table and chart share same endpoint)

        const url = `api/traceability-metrics/promotion/${promotionId}/${measure}-summary/unit/movements`;
        const { data } = await fetcher(url, { params });
        const summary = sumaryTotalsAdapter({ data: data.content });

        return summary;
    },
    async downloadTraceabilities(criteria) {
        const { size: _unused1, page: _unused2, ...params } = criteria;

        const { data: size } = await fetcher.get<number>('/api/tracing/container-traceability/count', {
            params: traceabilitiesCriteriaAdapter(params)
        });

        const { data } = await fetcher('api/tracing/traceabilities/csv', {
            params: traceabilitiesCriteriaAdapter({ ...params, size }),
            headers: { accept: 'text/csv' },
            responseType: 'blob'
        });

        await downloadFile(`Datos trazabilidad.csv`, data);
    }
};
