import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import {
    IconBan,
    IconCheckbox,
    IconCircleMinus,
    IconCirclePlus,
    IconExternalLink,
    IconInfoCircle,
    IconRotateClockwise2
} from '@tabler/icons';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import InputText from 'components/BasicComponents/Inputs/InputText';
import WasteFlowSelectorOption from 'components/BasicComponents/Select/CustomOptions/WasteFlowSelectorOption';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { useAutomaticReadingNotifications } from 'features/automatic-reading';
import { getTraceabilityCost } from 'features/new_edit_traceability/helpers/newEditTracability.helpers';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useDiExtraction } from 'modules/di-extraction';
import {
    DiExtractionWarningFields,
    DiExtractionWarning as DiExtractionWarningType,
    IDiExtraction
} from 'modules/di-extraction/domain/IDiExtraction';
import { usePromotion } from 'modules/promotion';
import { useWasteFlow } from 'modules/waste-flow';
import './DiExtraction.scss';
import DiExtractionWarning from './DiExtractionWarnings/DiExtractionWarning';

export interface DiExtractionProps {
    diExtraction: IDiExtraction;
}

const DiExtraction = ({ diExtraction }: DiExtractionProps) => {
    const [, analytics] = useAnalyticsStore();
    const notifications = useAutomaticReadingNotifications();

    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [{ loading, invalid, updating: removing }, { approveDiExtraction, rejectManyDiExtractions }] =
        useDiExtraction();
    const [, , { api }] = useWasteFlow();

    const initialPpgcl = diExtraction.result.ppgcl;

    const [traceability, setTraceability] = useState({
        traceabilityId: diExtraction.traceabilityId,
        date: diExtraction.result.date,
        weight: diExtraction.result.weight,
        cost: diExtraction.result.cost,
        volume: diExtraction.result.volume,
        numDi: diExtraction.result.numDi,
        ppgcl: initialPpgcl,
        incomplete: false,
        vehiclePlate: diExtraction.result.vehiclePlate
    });

    const [observations, setObservations] = useState(null);
    const [showObservations, setShowObservations] = useState(false);
    const [direction, setDirection] = useState<'H' | 'V'>('H');

    const error = invalid[diExtraction.id];
    const isRemoving = removing.includes(diExtraction.id);

    useEffect(() => {
        setTraceability({
            ...traceability,
            // Change volume and cost of the extraction only if there is no initial volume and cost
            volume: diExtraction.result.volume || traceability.ppgcl?.volume || null,
            cost: diExtraction.result.cost || calculateCost(traceability.ppgcl) || null
        });
    }, [JSON.stringify(traceability.ppgcl)]);

    const calculateCost = (ppgcl) => {
        return getTraceabilityCost(traceability.weight || 0, ppgcl?.cost?.unit, ppgcl?.cost?.ton);
    };

    const approveExtraction = async () => {
        const createdExtraction = await approveDiExtraction({
            id: diExtraction.id,
            traceabilityId: traceability.traceabilityId,
            ppgclId: traceability.ppgcl?.id!,
            date: traceability.date!,
            weight: traceability.weight!,
            cost: promotion?.permissions.costs ? traceability.cost : null,
            volume: traceability.volume,
            numDi: traceability.numDi,
            observations: observations,
            incomplete: traceability.incomplete,
            vehiclePlate: traceability.vehiclePlate
        });

        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_APPROVED);

        if (createdExtraction?.traceabilityId) notifications.created(createdExtraction.traceabilityId);
    };

    const rejectExtraction = async () => {
        await rejectManyDiExtractions({ ids: [diExtraction.id], promotionId: promotion?.id });
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_REJECTED);
    };

    const handleInputChange = ({ target }) => {
        let newValue = target.value;

        if ((target.name === 'cost' || target.name === 'volume') && !target.value) {
            newValue = null;
        }

        setTraceability({
            ...traceability,
            [target.name]: newValue
        });
    };

    const handleObservationsChange = ({ target }) => {
        setObservations(target.value);
    };

    const handleIncompleteCheckChange = (event) => {
        const { checked } = event.target;
        setTraceability({ ...traceability, incomplete: checked });
    };

    const toggleDirection = () => {
        if (direction === 'H') setDirection('V');
        else setDirection('H');
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_PREVIEW_ROTATED);
    };

    const [loadPpgclOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getProjectWasteFlows({ promotionId: promotion?.id, search, page }),
        adapter: (wasteFlowData, search) => ({
            value: wasteFlowData,
            label: (
                <WasteFlowSelectorOption
                    lerData={wasteFlowData.ler.code + ' ' + wasteFlowData.ler.type}
                    carrierName={wasteFlowData.carrier?.name}
                    wasteManagerName={wasteFlowData.manager.name}
                    search={search}
                />
            )
        })
    });

    const changePpgcl = (option) => {
        if (option === undefined) return;

        setTraceability({ ...traceability, ppgcl: option?.value || null });
    };

    const getWarnings = (field: DiExtractionWarningFields) => {
        // DON'T SHOW ANY WARNINGS if the user modified the initial value
        if (diExtraction.result[field] != traceability[field]) return;

        const warnings: DiExtractionWarningType[] = [...diExtraction.warnings].filter((warning) =>
            warning.fields.includes(field)
        );

        if (warnings.length === 0) return;

        // If multiple errors, display the icon of the most severe one
        const lowWarnings = warnings.filter((warning) => warning.severityLevel === 'LOW');
        const highWarnings = warnings.filter((warning) => warning.severityLevel === 'HIGH');

        if (warnings.length === 0) return null;

        return (
            <div className="diExtraction__warnings">
                {highWarnings.length > 0 && (
                    <DiExtractionWarning type={'HIGH'} messages={highWarnings.map((w) => w.message)} />
                )}
                {lowWarnings.length > 0 && (
                    <DiExtractionWarning type={'LOW'} messages={lowWarnings.map((w) => w.message)} />
                )}
            </div>
        );
    };

    const hasPreview = ['pdf', 'jpg', 'png'].includes(diExtraction.mimeType);
    const isImagePreview = ['jpg', 'png'].includes(diExtraction.mimeType);
    const isPdfPreview = diExtraction.mimeType === 'pdf';
    const isUnknownValPercent =
        traceability.ppgcl?.valuationPercentage === 1 || traceability.ppgcl?.valuationPercentage === null;

    const modifiers = {
        hasPreview: hasPreview ? 'diExtraction--has-preview' : '',
        isRemoving: removing.includes(diExtraction.id) ? 'diExtraction--removing' : '',
        isPending: loading === 'pending' ? 'diExtraction--pending' : '',
        displayDirection:
            (direction === 'V' && 'diExtraction--vertical') || (direction === 'H' && 'diExtraction--horizontal')
    };

    return (
        <div className={`diExtraction ${Object.values(modifiers).join(' ')}`} data-testid="DiExtractionDetail">
            <div className="diExtraction__preview">
                {isPdfPreview && <iframe src={diExtraction.fileUrl} />}
                {isImagePreview && <img src={diExtraction.fileUrl} />}
                {!hasPreview && <p className="diExtraction__previewExtension">{diExtraction.mimeType}</p>}

                <div className="diExtraction__iframeButtons">
                    <SmallButton
                        type="button"
                        titulo={t('rotate', { ns: 'diExtraction' })}
                        icon={<IconRotateClockwise2 />}
                        iconPosition="left"
                        action={() => toggleDirection()}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    {diExtraction.fileUrl && (
                        <SmallButton
                            type="external-link"
                            titulo={t('show.show')}
                            icon={<IconExternalLink />}
                            iconPosition="left"
                            to={diExtraction.fileUrl}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    )}
                </div>
            </div>
            <div className="diExtraction__content">
                <form>
                    <div>
                        <div className="diExtraction__row">
                            <SelectAutocomplete
                                name="wasteFlow"
                                defaultOptions={true}
                                label={t('wasteFlow', { ns: 'diExtraction' })}
                                onChange={(option) => changePpgcl(option)}
                                loadOptions={loadPpgclOptions}
                                value={
                                    initialPpgcl
                                        ? ({
                                              value: initialPpgcl,
                                              label: (
                                                  <WasteFlowSelectorOption
                                                      lerData={initialPpgcl.ler.code + ' ' + initialPpgcl.ler.type}
                                                      carrierName={initialPpgcl.carrier?.name}
                                                      wasteManagerName={initialPpgcl.manager.name}
                                                      search={''}
                                                  />
                                              )
                                          } as SelectOption)
                                        : undefined
                                }
                                extraElement={getWarnings('ppgcl')}
                            />
                            <div className="diExtraction__break" />
                            {traceability.ppgcl && (
                                <div className="diExtraction__ppgclInfo">
                                    <p>
                                        {/* TODO: Unknown valorization percent should change to "null" */}
                                        {isUnknownValPercent
                                            ? '% de valorización desconocido'
                                            : `${traceability.ppgcl?.valuationPercentage}% de valorización`}
                                    </p>

                                    <p>
                                        Tratamiento {traceability.ppgcl.treatment.code}
                                        <span
                                            data-for={`tooltip${diExtraction.id}`}
                                            data-tip={traceability.ppgcl.treatment.description}
                                            data-offset="{'top': -10}"
                                        >
                                            <IconInfoCircle stroke={2} size={18} />
                                            <ReactTooltip
                                                id={`tooltip${diExtraction.id}`}
                                                delayHide={100}
                                                effect="solid"
                                                multiline={true}
                                                className="newTraceability__tooltip"
                                                event="click"
                                                globalEventOff={'click'}
                                            />
                                        </span>
                                    </p>
                                </div>
                            )}
                            <InputFecha
                                inputContainer="diExtraction__input-date"
                                className="diExtraction__input"
                                label={t('date.date', { ns: 'common' })}
                                name="date"
                                value={traceability.date}
                                values={traceability}
                                setValues={setTraceability}
                                onChange={handleInputChange}
                                validacion={!!error}
                                maxDays={0}
                                extraElement={getWarnings('date')}
                                required
                            />
                            <InputText
                                inputContainer="diExtraction__input-numDi"
                                className="diExtraction__input"
                                label={t('di.number', { ns: 'traceability' })}
                                name="numDi"
                                value={traceability.numDi}
                                onChange={handleInputChange}
                                validacion={!!error}
                                extraElement={getWarnings('numDi')}
                            />

                            {direction === 'H' && <div className="diExtraction__break" />}

                            <InputNumber
                                className="diExtraction__input diExtraction__input-small"
                                label={t('weight.upperCase', { ns: 'common' }) + ' (T)'}
                                name="weight"
                                id="weight"
                                value={traceability.weight}
                                showThousandSeparator={false}
                                onChange={handleInputChange}
                                validacion={!!error}
                                extraElement={getWarnings('weight')}
                                required
                                maximumFractionDigits={3}
                                min="1"
                            />

                            {promotion?.permissions.costs && (
                                <div className="diExtraction__field diExtraction__cost">
                                    <InputNumber
                                        className="diExtraction__input diExtraction__input-small"
                                        label={t('cost.upperCase', { ns: 'costs' }) + ' (€)'}
                                        name="cost"
                                        value={traceability.cost || ''}
                                        onChange={handleInputChange}
                                        // Just to ignore optional fields
                                        {...({} as any)}
                                    />
                                    <p>
                                        {!!traceability?.ppgcl?.cost?.unit && (
                                            <p>Unitario: {traceability?.ppgcl?.cost?.unit}€</p>
                                        )}
                                        {!!traceability?.ppgcl?.cost?.ton && (
                                            <p>Tonelada: {traceability?.ppgcl?.cost?.ton}€</p>
                                        )}
                                    </p>
                                </div>
                            )}

                            <InputNumber
                                className={`diExtraction__input diExtraction__input-small`}
                                label={t('volume.upperCase', { ns: 'traceability' }) + ' (m³)'}
                                name="volume"
                                value={traceability.volume || ''}
                                onChange={handleInputChange}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />

                            <InputText
                                label={t('detail.vehiclePlate', { ns: 'traceabilities' })}
                                name="vehiclePlate"
                                inputContainer="diExtraction__input-vehiclePlate"
                                className="diExtraction__input"
                                value={traceability.vehiclePlate}
                                onChange={handleInputChange}
                                {...({} as any)}
                            />

                            <div className="diExtraction__break diExtraction__break--always" />

                            <label className="diExtraction__incompleteCheck">
                                <input
                                    type="checkbox"
                                    checked={traceability.incomplete}
                                    onChange={(event) => handleIncompleteCheckChange(event)}
                                />
                                <span>DI incompleto o incorrecto</span>
                            </label>
                            <div className="diExtraction__break diExtraction__break--always" />

                            {showObservations && (
                                <InputText
                                    label={t('detail.observations', { ns: 'traceabilities' })}
                                    name="observations"
                                    value={observations}
                                    onChange={handleObservationsChange}
                                    className="diExtraction__input diExtraction__observations"
                                    inputContainer="diExtraction__input-fullWidth"
                                    {...({} as any)}
                                />
                            )}
                        </div>
                    </div>
                    {error && (
                        <ErrorCard className="diExtraction__error" error={error}>
                            <ul>
                                {error.cause.content.errors.map((e) => (
                                    <li key={e.field}>{e.error}</li>
                                ))}
                            </ul>
                        </ErrorCard>
                    )}
                    <div className="diExtraction__buttons">
                        <p
                            className="diExtraction__showObservations"
                            onClick={() => setShowObservations(!showObservations)}
                        >
                            {showObservations ? <IconCircleMinus /> : <IconCirclePlus />}
                            <span>{showObservations ? 'Ocultar' : 'Añadir'} observaciones</span>
                        </p>
                        <BasicButton
                            type="button"
                            color="red"
                            text={t('reject', { ns: 'diExtraction' })}
                            icon={<IconBan stroke={2} size={20} color="white" />}
                            extraClasses="button__small"
                            isLoading={isRemoving}
                            action={() => rejectExtraction()}
                        />
                        <SaveButton
                            type="button"
                            color="green"
                            text={t('approve', { ns: 'diExtraction' })}
                            icon={<IconCheckbox stroke={2} size={20} color="white" />}
                            extraClasses="button__small"
                            isLoading={isRemoving}
                            action={() => approveExtraction()}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DiExtraction;
