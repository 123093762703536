import { Cache } from 'lib/cache';
import fetcher from 'lib/fetcher';
import { Comparative } from '../../domain/Comparative';
import { ComparativeApi } from '../../domain/ComparativeApi';

export class HttpComparativeApi implements ComparativeApi {
    constructor(private cache: Cache<Comparative>) {}

    async findAll() {
        async function getComparativeData(): Promise<Comparative> {
            const { data } = await fetcher.get('/api/comparative');
            return data;
        }

        const comparative = await this.cache.cacheQuery(getComparativeData);
        return comparative?.data;
    }

    async updateObservations({ promotionId, text }) {
        const request = await fetcher.put(`/api/comparative/${promotionId}/observations`, { text });

        return { success: request.status === 204 };
    }
}
