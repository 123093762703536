import { comparativeStore } from 'modules/comparative/application/comparativeStore';
import { contextStoreAdapter } from 'modules/shared';

const Ctx = contextStoreAdapter(comparativeStore)({
    name: 'ComparativeStore'
});

export const ComparativeProvider = Ctx.Provider;

// required .bind(api) to avoid losing the context inside Class implementation for api
export const useComparative = Ctx.createQueryHook(({ api }) => api.findAll.bind(api));

export const useUpdateObservations = Ctx.createMutationHook(({ api }) => api.updateObservations.bind(api));

export const useComparativeCacheMeta = Ctx.createQueryHook(({ cache }) => cache.meta.bind(cache));
export const useComparativeCacheInvalidation = Ctx.createMutationHook(({ cache }) => cache.invalidate.bind(cache));

export const useComparativeCache = () => {
    const meta = useComparativeCacheMeta();
    const invalidate = useComparativeCacheInvalidation();

    return { meta, invalidate };
};
