import { PERMISSIONS, BASE_PERMISSIONS } from '../constants/roles.constants';

export const usersWithComparativeAccess = [
    'rbueno@metrovacesa.com',
    'amenendez@metrovacesa.com',
    'cperez@metrovacesa.com',
    'p_demo@cocircular.es',
    'c_demo@cocircular.es'
];

export const hasPermission = (section, requiredPermission, user, promotion) => {
    if (!requiredPermission || !section) return true;

    if (section === 'comparative' && usersWithComparativeAccess.includes(user.email)) return true;

    let isAllowedTo = false;
    const roles = user?.authorities;

    const permissionsArray = getAllPermissions(roles);
    const mergedPermissions = mergePermissions(permissionsArray);

    // In the majority of cases, 1 concrete permission from 1 section is required. But sometimes we will receive an array of multiple sections
    // (we check the permission of both sections and we allow access if user has permission in at least one section)
    if (Array.isArray(section)) {
        isAllowedTo = checkPermissionWhenMultipleSections(mergedPermissions, section, requiredPermission);
    } else {
        isAllowedTo = mergedPermissions?.[section]?.includes(requiredPermission) || false;
    }

    return isAllowedTo;
};

const getAllPermissions = (roles) => {
    const permissions = [];
    roles?.forEach((role) => {
        if (PERMISSIONS[role] !== undefined) {
            permissions.push(PERMISSIONS[role]);
        }
    });

    return permissions;
};

const mergePermissions = (permissions) => {
    const mergedPermissions = { ...BASE_PERMISSIONS };

    for (const section in mergedPermissions) {
        for (let i = 0; i < permissions?.length; i++) {
            const currentPermission = permissions[i];
            // Combining both arrays of permissions
            mergedPermissions[section] = [...mergedPermissions[section], ...currentPermission[section]];
            // Removing duplicates
            mergedPermissions[section] = [...new Set(mergedPermissions[section])];
        }
    }

    return mergedPermissions;
};

const checkPermissionWhenMultipleSections = (permissions, sections, requiredPermission) => {
    let isAllowedTo = false;

    for (let i = 0; i < sections?.length && !isAllowedTo; i++) {
        const section = sections[i];

        isAllowedTo = permissions[section]?.includes(requiredPermission) || false;
    }

    return isAllowedTo;
};
